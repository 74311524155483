import { GatsbyBrowser, navigate } from 'gatsby';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = (args) => {
  const isInitialLoad = !args.prevLocation && args.location.hash;
  const isPageChange =
    args.location.pathname !== args.prevLocation?.pathname &&
    args.location.hash;

  // add delay to navigation if anchor link is the target on page changes as well as during the initial load
  if (isInitialLoad || isPageChange) {
    // make sure to navigate AFTER the site has been rendered
    // @ts-expect-error: ignore number error
    setTimeout(() => navigate(args.location.hash), 200);
  }
};
